import Vue from "vue";
import Router from "vue-router";

const TheContainer = () => import("@/containers/TheContainer");
const Dashboard = () => import("@/views/Dashboard");

/* Consultas */
const InformativaGeneralPaquetes = () => import("@/views/base/InformativaGeneralPaquetes");
const FacturacionElectronica = () => import("@/views/base/FacturacionElectronica");
const Anulacion = () => import("@/views/base/Anulacion");
const InformativaClub = () => import("@/views/base/InformativaClub");
//const PeruQuiosco = () => import("@/views/base/PeruQuiosco");
//const IngresoBeneficiario = () => import("@/views/base/IngresoBeneficiario");
const Paywall = () => import("@/views/base/Paywall");
const DevolucionDinero = () => import("@/views/base/DevolucionDinero");
//const C2C = () => import("../views/base/C2C");
const ConsultaCobroDoble = () => import("@/views/base/ConsultaCobroDoble")
/* Actualizacion */
//const ActivacionPQ = () => import("@/views/base/ActivacionCuentaPQ");
//const ActualizacionPQ = () => import("@/views/base/ActualizacionPQ");
const ActuzalizaNombreRazonSocial = () => import("@/views/base/ActuzalizaNombreRazonSocial");
const ActualizaDocumentoIdentidad = () => import("@/views/base/ActualizaDocumentoIdentidad");
const ActualizaFormaEntrega = () => import("@/views/base/ActualizaFormaEntrega");
const AactualizaTarjetPagos = () => import("@/views/base/AactualizaTarjetPagos");
const ActualizacionDatosPersonales = () =>
  import("@/views/base/ActualizacionDatosPersonales");
//const ActualizacionTarjeta = () => import("@/views/base/ActualizacionTarjeta");
const ActualizacionCambioDireccion = () => import("@/views/base/ActualizacionCambioDireccion")
/* Reclamos */
const ReclamosNoMeLlegoElDiario = () => import("@/views/base/ReclamosNoMeLlegoElDiario");
const ReclamosEdicionMaltratada = () => import("@/views/base/ReclamosEdicionMaltratada")
const ReclamosEntregaIncompleta = () => import("@/views/base/ReclamosEntregaIncompleta")
const ReclamosHorarioDeEntrega = () => import("@/views/base/ReclamosHorarioDeEntrega")
const ReclamosFormaDeEntrega = () => import("@/views/base/ReclamosFormaDeEntrega")
/* Peru quiosco */
const PqActivacionCuenta = () => import("@/views/base/PqActivacionCuenta");
const PqCambiuoContraseña = () => import("@/views/base/PqCambiuoContraseña");
const PqConsulta = () => import("@/views/base/PqConsulta");
const PqProblemasAacceso = () => import("@/views/base/PqProblemasAacceso");
/* Cancelar suscripcion */
const CancelarSuscripcion = () => import("@/views/base/CancelarSuscripcion");
const ClubComercioComoSerDelClub = () => import("@/views/base/ClubComercioComoSerDelClub");
const ClubComercioPromociones = () => import("@/views/base/ClubComercioPromociones");
const ClubComercioComoUsarBeneficios = () => import("@/views/base/ClubComercioComoUsarBeneficios");
const ClubComercioCambiarBeneficiario = () => import("@/views/base/ClubComercioCambiarBeneficiario");
const ClubComercioActualizarDatos = () => import("@/views/base/ClubComercioActualizarDatos");
const ClubComercioReclamoClubSuscri = () => import("@/views/base/ClubComercioReclamoClubSuscri");





// Views - Pages
import Page404 from "../views/pages/Page404";
import Page500 from "../views/pages/Page500";
import Login from "../views/pages/Login";
import Landing from "../views/pages/Landing";
import Register from "../views/pages/Register";
import RecuperarPassword from "../views/pages/RecuperarPassword";
import ResetearPassword from "../views/pages/ResetearPassword";
import PaginaActivacion from "../views/pages/PaginaActivacion";
import PageCambioDireccion from "../views/pages/PageCambioDireccion";
import PageIncidenciaReparto from "../views/pages/PageIncidenciaReparto";
import PageOtrasConsultasSolicitudes from "../views/pages/PageOtrasConsultasSolicitudes";
import PageCambioDireccionCustomer from "../views/pages/PageCambioDireccionCustomer";
import PageCambioTitularidad from "../views/pages/PageCambioTitularidad";
import PageCambioContratanteFacturador from "../views/pages/PageCambioContratanteFacturador";
import PageIncidenciaCobroDoble from "../views/pages/PageIncidenciaCobroDoble";
import PageIncidenciaDobleCobro from "../views/pages/PageIncidenciaDobleCobro";
/* TODO: Formularios Sprint 22 */
import ConsultaGeniogramista from '../views/pages/ConsultaGeniogramista';
import ActualizacionDniRuc from '../views/pages/ActualizacionDniRuc';
import ActualizacionContratanteFacturador from '../views/pages/ActualizacionContratanteFacturador';
import ReclamoClubSuscriptores from '../views/pages/ReclamoClubSuscriptores';
import ActualizacionFormaEntrega from "../views/pages/ActualizacionFormaEntrega";
import pageCargoAutomatico from "../views/pages/pageCargoAutomatico";
/* TODO: Formularios Sprint 23 */
import ActualizarCorreoDigital from '../views/pages/ActualizarCorreoDigital';
import pageCambioPeriodo from '../views/pages/pageCambioPeriodo';
import MigracionPeruQuiosco from '../views/pages/MigracionPeruQuiosco'
/* TODO:Formulario sprint 24 */
import ActualizarDatosBeneficiarioClub from '../views/pages/ActualizarDatosBeneficiarioClub'
/* TODO: Soporte RPA BPI*/
import PageServicioTecnicoBpi from '../views/pages/PageServicioTecnicoBpi'
import PageStatusServicioTecnicoBpi from '../views/pages/PageStatusServicioTecnicoBpi'
import PageFueraServicio from '../views/pages/PageFueraServicio'
/* Sprint 34 cambio direccion playa */
import PageCambioDireccionPlaya from '../views/pages/PageCambioDireccionPlaya'
/* Formulario para creacion de tickets fuera de horario Alicia Ventas */
import PageFueraDeHorarioAliciaVentas from '../views/pages/PageFueraDeHorarioAliciaVentas'


Vue.use(Router);

export default new Router({
  mode: "hash",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {

  return [
    {
      path: "/dashboard",
      redirect: "/dashboard/inicio",
      name: "Inicio",
      component: TheContainer,

      children: [
        //inicio 
        {
          path: "inicio",
          name: "Tablero informativo",
          component: Dashboard,
          beforeEnter: (to, from, next) => {
            if (
              localStorage.getItem(
                `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
              ) == "1"
            ) {
              next();
            } else {
              next("/login");
            }
          },
        },
        //Cancelar suscripcion
        {
          path: "cancelar-suscripcion",
          name: "Cancelar suscripcion",
          component: CancelarSuscripcion,
          beforeEnter: (to, from, next) => {
            if (
              localStorage.getItem(
                `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
              ) == "1"
            ) {
              next();
            } else {
              next("/login");
            }
          }
        },
        //consultas
        {
          path: "consultas",
          redirect: "/consultas/beneficio-club-comercio",
          name: "Consultas",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            //Estado de susripcion 
            {
              path: "estado-suscripcion",
              name: "Estado de suscripción",
              component: InformativaGeneralPaquetes,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
            //Consulta renoovacion
            {
              path: "consulta-renovacion",
              name: "Consulta de renovación",
              component: InformativaClub,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
            //Comprobante de pago
            {
              path: "comprobante-pago",
              name: "Comprobante de pago",
              component: FacturacionElectronica,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
            //estado-anulacion
            {
              path: "estado-anulacion",
              name: "Estado de anulación",
              component: Anulacion,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },

            //Estado Devolucion :devolucion de dinero
            {
              path: "devolucion-dinero",
              name: "Estdado devolución de dinero",
              component: DevolucionDinero,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
            //Estado devolucion : cobro doble
            {
              path: "cobro-doble",
              name: "Estado cobro doble",
              component: ConsultaCobroDoble,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //geniogramista
            {
              path: "geniogramista",
              name: "Geniogramista",
              component: Paywall,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
          ],
        },
        //Club el Comercio
        {
          path: "club-comercio",
          redirect: "/club-comercio/ser-parte-del-club",
          name: "Club El Comercio",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            //como ser parte del club
            {
              path: "ser-parte-del-club",
              name: "ser parte del club",
              component: ClubComercioComoSerDelClub,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //promociones
            {
              path: "promociones",
              name: "Promociones",
              component: ClubComercioPromociones,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //beneficios
            {
              path: "beneficios",
              name: "Beneficios",
              component: ClubComercioComoUsarBeneficios,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //cambiar un beneficiario
            {
              path: "cambiar-beneficiario",
              name: "Cambiar beneficiario",
              component: ClubComercioCambiarBeneficiario,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //actualizar datos
            {
              path: "actualizar-datos",
              name: "Actualizar datos",
              component: ClubComercioActualizarDatos,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //reclamo club suscriptor
            {
              path: "reclamo-club-suscriptores",
              name: "Reclamo club suscriptores",
              component: ClubComercioReclamoClubSuscri,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            }
          ]
        },
        //peru quiosco
        {
          path: "peru-quiosco",
          redirect: "/peru-quiosco/consulta-pq",
          name: "PerúQuiosco",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            //consulta pq
            {
              path: "consulta-pq",
              name: "Consulta de PQ",
              component: PqConsulta,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //activar pq cuenta
            {
              path: "activar-cuenta-pq",
              name: "Activacion de cuenta",
              component: PqActivacionCuenta,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //cambio de contraseña
            {
              path: "cambiar-contrasena-pq",
              name: "Cambio de contraseña",
              component: PqCambiuoContraseña,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //problemas de acceso
            {
              path: "problemas-acceso-pq",
              name: "Problemas de acceso",
              component: PqProblemasAacceso,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            }
          ]
        },
        //Aactualizaciones
        {
          path: "actualizacion",
          redirect: "/actualizacion/datos-personales",
          name: "Actualizacion",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            //actualizacion nombre o razon social
            {
              path: "nombre-razon-social",
              name: "Nombre o razon social",
              component: ActuzalizaNombreRazonSocial,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //actualizar documeto de identidad
            {
              path: "documento-identidad",
              name: "Documento identidad",
              component: ActualizaDocumentoIdentidad,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //actualizacion datos pesonales(listo)
            {
              path: "datos-personales",
              name: "Datos Personales",
              component: ActualizacionDatosPersonales,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
            //Actualizacion cambio de direccion(listo)
            {
              path: "cambio-direccion",
              name: "Cambio Direccion",
              component: ActualizacionCambioDireccion,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //actualizacion forma
            {
              path: "cambio-forma-entrega",
              name: "Forma de entrega",
              component: ActualizaFormaEntrega,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //actualizacion tarjeta de pagos
            {
              path: "tarjeta-pagos",
              name: "Tarjeta de pagos",
              component: AactualizaTarjetPagos,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
             //actualizacion activar cuenta (listo)
             {
              path: "activacion-cuenta",
              name: "Activar cuenta",
              component: PqActivacionCuenta,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },

            },
            //actualizacion activar contraseña (listo)
            {
              path: "contraseña",
              name: "Actualizar contraseña",
              component: PqCambiuoContraseña,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },  
          ],
        },
        //Reclamos
        {
          path: "reclamos",
          redirect: "/reclamos/no-me-llego-el-diario",
          name: "Reclamos",
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            //reclamos no me llego el diario
            {
              path: "no-me-llego-el-diario",
              name: "No me llego el diario",
              component: ReclamosNoMeLlegoElDiario,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              },
            },
            //reclamos edicion maltratada
            {
              path: "edicion-maltratada",
              name: "Edicion maltratada",
              component: ReclamosEdicionMaltratada,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //reclamos entrega incompleta
            {
              path: "entrega-incompleta",
              name: "Entrega Incompleta",
              component: ReclamosEntregaIncompleta,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //reclamos horario de entrega
            {
              path: "horario-de-entrega",
              name: "Horario de entrega",
              component: ReclamosHorarioDeEntrega,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            },
            //reclamos forma de entrega
            {
              path: "forma-de-entrega",
              name: "Forma de entrega",
              component: ReclamosFormaDeEntrega,
              beforeEnter: (to, from, next) => {
                if (
                  localStorage.getItem(
                    `${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`
                  ) == "1"
                ) {
                  next();
                } else {
                  next("/login");
                }
              }
            }
          ]
        }
      ],
    },
    {
      path: "/",
      redirect: "/",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
        {
          path: "",
          name: "Landing",
          component: Landing,
        },
        {
          path: "recuperar-password",
          name: "RecuperarPassword",
          component: RecuperarPassword,
        },
        {
          path: "resetear-password",
          name: "ResetearPassword",
          component: ResetearPassword,
        },
        {
          path: "activar-cuenta",
          name: "PaginaActivacion",
          component: PaginaActivacion,
        },
        {
          path: "error",
          name: "Page500",
          component: Page500,
        },
        {
          path: "cambio-direccion",
          name: "PageCambioDireccion",
          component: PageCambioDireccion,
        },
        {
          path: "incidencia-reparto",
          name: "PageIncidenciaReparto",
          component: PageIncidenciaReparto,
        },
        {
          path: "otras-consultas-solicitudes",
          name: "PageOtrasConsultasSolicitudes",
          component: PageOtrasConsultasSolicitudes,
        },
        {
          path: "incidencia-cobro-doble",
          name: "PageIncidenciaCobroDoble",
          component: PageIncidenciaCobroDoble,
        },
        {
          path: "consulta-geniogramista",
          name: "ConsultaGeniogramista",
          component: ConsultaGeniogramista,
        },
        {
          path: "actualizacion-dni-ruc",
          name: "ActualizacionDniRuc",
          component: ActualizacionDniRuc,
        },
        {
          path: "actualizacion-contratante-facturador",
          name: "ActualizacionContratanteFacturador",
          component: ActualizacionContratanteFacturador,
        },
        {
          path: "reclamo-club-suscriptores",
          name: "ReclamoClubSuscriptores",
          component: ReclamoClubSuscriptores,
        },
        {
          path: "actualizacion-forma-entrega",
          name: "ActualizacionFormaEntrega",
          component: ActualizacionFormaEntrega
        },
        {
          path: "cargo-automatico",
          name: "pageCargoAutomatico",
          component: pageCargoAutomatico
        },
        {
          path: "actualizar-correo-digital",
          name: "ActualizarCorreoDigital",
          component: ActualizarCorreoDigital
        },
        {
          path: "cambio-periodo",
          name: "pageCambioPeriodo",
          component: pageCambioPeriodo
        },
        {

          path: "migracion-peru-quiosco",
          name: "MigracionPeruQuiosco",
          component: MigracionPeruQuiosco
        },
        {
          path: "actualizar-datos-beneficiario-club",
          name: "ActualizarDatosBeneficiarioClub",
          component: ActualizarDatosBeneficiarioClub
        },
        {
          path: `cambio-titularidad/:token/:nrodoc/:typedoc/:positionid/:phone/:firstname/:lastname/:docNumber/:docType`,
          name: "PageCambioTitularidad",
          component: PageCambioTitularidad,

        },
        {
          path: `cambio-contratante-facturador/:token/:nrodoc/:typedoc/:positionid/:firstname/:docNumber/:docType`,
          name: "PageCambioContratanteFacturador",
          component: PageCambioContratanteFacturador,

        },
        {
          path: `cambio-direccion-cliente/:token/:nrodoc/:typedoc/:positionid`,
          name: "PageCambioDireccionCliente",
          component: PageCambioDireccionCustomer,
        },
        {
          path: `incidencia-doble-cobro/:token/:nrodoc/:typedoc/:positionid`,
          name: "PageIncidenciaDobleCobro",
          component: PageIncidenciaDobleCobro,
        },
        {
          path: `support-register-ticket-rpa`,
          name: "PageServicioTecnicoBpi",
          component: PageServicioTecnicoBpi,
        },
        {
          path: `support-view-status-ticket-rpa`,
          name: "PageStatusServicioTecnicoBpi",
          component: PageStatusServicioTecnicoBpi,
        },
        {
          path: `fuera-servicio`,
          name: "PageFueraServicio",
          component: PageFueraServicio,
        },
        {
          path: "cambio-direccion-playa",
          name: "PageCambioDireccionPlaya",
          component: PageCambioDireccionPlaya,
        },
        {
          path: "alicia-ventas",
          name: "PageFueraDeHorarioAliciaVentas",
          component: PageFueraDeHorarioAliciaVentas,
        }
      ],
    },
    {
      path: "*",
      component: Page404,
    },
  ];
}
